<template>
  <div class="scoreboard">
    <div class="scoreboard__head">
      <div class="scoreboard__head-main">
        <div class="scoreboard__team scoreboard__team--left">
          <BaseballClock
            v-if="isBaseball"
            :side="teamA.side"
            :team-in-possession="teamInPossession"
          />
          <ShotClock
            v-else
            :percentage="100"
            :icon="event.sportId"
            :is-active="isUSAView ? teamInPossession === 'AWAY' : teamInPossession === 'HOME'"
          />
          <span class="scoreboard__team-side">
            {{ teamA.label }}
          </span>
          <span class="scoreboard__team-name">
            {{ teamA.name }}
          </span>
          <FootballTimeoutIndicators
            v-if="live && isFootball"
            :period="event.period"
            :timeouts-left="teamA.timeoutsLeft"
          />
        </div>

        <div class="scoreboard__info">
          <span
            :class="[
              'scoreboard__info-time',
              { 'scoreboard__info-time--prematch': prematch },
              { 'scoreboard__info-time--live': live },
              { 'scoreboard__info-time--finished': finished },
              { 'scoreboard__info-time--finished': canceled },
            ]"
          >
            {{ time }}
          </span>

          <span
            v-if="prematch"
            class="scoreboard__info-score"
          >
            -
          </span>
          <span
            v-else
            class="scoreboard__info-score"
          >
            {{ teamA.score }} - {{ teamB.score }}
          </span>
        </div>

        <div class="scoreboard__team scoreboard__team--right">
          <BaseballClock
            v-if="isBaseball"
            :side="teamB.side"
            :team-in-possession="teamInPossession"
          />
          <ShotClock
            v-else
            :percentage="100"
            :icon="event.sportId"
            :is-active="isUSAView ? teamInPossession === 'HOME' : teamInPossession === 'AWAY'"
          />
          <span class="scoreboard__team-side">
            {{ teamB.label }}
          </span>
          <span class="scoreboard__team-name">
            {{ teamB.name }}
          </span>
          <FootballTimeoutIndicators
            v-if="live && isFootball"
            :period="event.period"
            :timeouts-left="teamB.timeoutsLeft"
            is-reversed
          />
        </div>
      </div>
      <BaseballIndicators
        v-if="live && isBaseball"
        :balls="event.balls"
        :strikes="event.strikes"
        :outs="event.outs"
        :base1="event.base1"
        :base2="event.base2"
        :base3="event.base3"
      />
      <div
        v-if="live && isBaseball"
        class="scoreboard__baseball-meta"
      >
        <div class="scoreboard__baseball-meta-group">
          <div class="scoreboard__baseball-meta-label">
            <Icon name="pitcher" />
            Pitcher
          </div>
          <div
            class="scoreboard__baseball-meta-value"
            :title="pitcherName"
          >
            {{ pitcherName }}
          </div>
        </div>
        <div class="scoreboard__baseball-meta-group">
          <div class="scoreboard__baseball-meta-label">
            Pitch Count
          </div>
          <div class="scoreboard__baseball-meta-value">
            {{ pitchCount }}
          </div>
        </div>
        <div class="scoreboard__baseball-meta-group">
          <div class="scoreboard__baseball-meta-label">
            Batting Order
          </div>
          <div class="scoreboard__baseball-meta-value">
            {{ timesThrough }}
          </div>
        </div>
        <div class="scoreboard__baseball-meta-group">
          <div class="scoreboard__baseball-meta-label">
            <Icon name="hitter" />
            Hitter
          </div>
          <div
            class="scoreboard__baseball-meta-value"
            :title="hitterName"
          >
            {{ hitterName }}
          </div>
        </div>
      </div>
      <div
        v-if="live && isFootball"
        class="scoreboard__football-meta"
      >
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            Down
          </div>
          <div class="scoreboard__football-meta-value">
            {{ footballDownValue }}
          </div>
        </div>
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            To go
          </div>
          <div class="scoreboard__football-meta-value">
            {{ footballToGoValue }}
          </div>
        </div>
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            On
          </div>
          <div class="scoreboard__football-meta-value">
            {{ footballOnValue }}
          </div>
        </div>
        <div class="scoreboard__football-meta-group">
          <div class="scoreboard__football-meta-label">
            Play running
          </div>
          <div class="scoreboard__football-meta-value">
            <BooleanIndicator
              v-if="footballPlayValueAvailable"
              :value="footballPlayValue"
            />
            <span v-else>
              -
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showPlayByPlay"
      class="scoreboard__play-by-play"
    >
      <PlayByPlay
        :event="event"
      />
    </div>
    <div
      v-if="isSportSupported && !prematch && !hasDpsTraderRole"
      class="scoreboard__body"
    >
      <Button
        variant="tertiary"
        :icon="expandedIcon"
        @click="toggleExpansion"
        icon-right
        small
      >
        {{ expandedLabel }}
      </Button>

      <div
        class="scoreboard-navigation"
        v-show="expanded"
      >
        <div
          :class="['scoreboard-navigation-tab', { 'selected': selectedScoreboardTab === 'event-details' }]"
          @click="selectScoreboardTab('event-details')"
        >
          Event details
        </div>
        <div
          :class="['scoreboard-navigation-tab', { 'selected': selectedScoreboardTab === 'player-statistics' }]"
          v-if="event.sport.id === '841e188b-0dcf-4f5f-974f-aa52c8cec95b' || event.sport.id === 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f' || event.sport.id === '4e8eca10-6afa-44ed-af77-42414ec45cb3'"
          @click="selectScoreboardTab('player-statistics')"
        >
          Player statistics
        </div>
      </div>

      <ScoreboardDetails
        v-show="expanded && selectedScoreboardTab === 'event-details'"
      />

      <ScoreboardPlayerStatistics
        v-if="expanded && selectedScoreboardTab === 'player-statistics'"
        :event="event"
      />
    </div>
  </div>
</template>

<script>
import { includes, find, isNil } from 'lodash';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { differenceInDays, startOfDay, format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useSquad } from '@/composables';
import sportIds from '@/services/helpers/sports';
import Event from '@/services/helpers/event';
import Soccer from '@/services/helpers/soccer';
import Basketball from '@/services/helpers/basketball';
import Football from '@/services/helpers/football';
import Ultimate from '@/services/helpers/ultimate';
import Hockey from '@/services/helpers/hockey';
import Baseball from '@/services/helpers/baseball';
import { isPlayByPlayDisplayable } from '@/services/helpers/play-by-play';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import BaseballClock from '@/components/common/BaseballClock';
import ShotClock from '@/components/common/ShotClock';
import PlayByPlay from '@/components/common/PlayByPlay';
import BooleanIndicator from '@/components/common/BooleanIndicator';
import BaseballIndicators from '@/components/common/BaseballIndicators';
import FootballTimeoutIndicators from '@/components/common/FootballTimeoutIndicators';
import ScoreboardDetails from './ScoreboardDetails';
import ScoreboardPlayerStatistics from './ScoreboardPlayerStatistics';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  SOCCER_ID,
  ULTIMATE_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export default {
  components: {
    Icon,
    Button,
    BaseballClock,
    ShotClock,
    PlayByPlay,
    BooleanIndicator,
    BaseballIndicators,
    FootballTimeoutIndicators,
    ScoreboardDetails,
    ScoreboardPlayerStatistics,
  },
  setup() {
    const store = useStore();
    const hasDpsTraderRole = computed(() => store.getters.isDpsTraderRole);
    const event = computed(() => store.getters.event);
    const teamInPossession = computed(() => (event.value?.matchState === 'LIVE' ? (event.value?.teamInPossession || '') : ''));

    const selectedScoreboardTab = ref('event-details');

    const teamA = computed(() => {
      const label = event.value.isUSAView ? 'Away' : 'Home';
      const team = event.value.isUSAView ? event.value.away : event.value.home;
      return { ...team, label };
    });
    const teamB = computed(() => {
      const label = event.value.isUSAView ? 'Home' : 'Away';
      const team = event.value.isUSAView ? event.value.home : event.value.away;
      return { ...team, label };
    });

    const sportUtils = computed(() => {
      switch (event.value.sport.id) {
      case SOCCER_ID:
        return Soccer;
      case BASKETBALL_ID:
        return Basketball;
      case FOOTBALL_ID:
        return Football;
      case ULTIMATE_ID:
        return Ultimate;
      case HOCKEY_ID:
        return Hockey;
      case BASEBALL_ID:
        return Baseball;
      default:
        return null;
      }
    });
    const isSportSupported = computed(() => !!sportUtils.value);
    const isBaseball = computed(() => event.value?.sportId === BASEBALL_ID);
    const isFootball = computed(() => event.value?.sportId === FOOTBALL_ID);
    const prematch = computed(() => Event.isPrematch(event.value));
    const live = computed(() => Event.isLive(event.value) && event.value.period !== 'EVENT_COMPLETED');
    const livePeriods = computed(() => sportUtils.value?.getLivePeriods(event.value) || []);
    const finished = computed(() => Event.isFinished(event.value));
    const canceled = computed(() => Event.isCanceled(event.value));
    const time = computed(() => {
      if (canceled.value) return 'Canceled';
      if (finished.value) return 'Finished';
      if (live.value && !includes(livePeriods.value, event.value.period)) return 'Live';
      if (!prematch.value) return sportUtils.value.getPeriodLabel(event.value);

      const zonedTime = zonedTimeToUtc(event.value.startsAt, 'UTC');
      const daysUntilMatch = differenceInDays(startOfDay(zonedTime), startOfDay(new Date()));
      if (daysUntilMatch < 0) return 'In 0 days';

      const daysVariant = daysUntilMatch === 1 ? 'day' : 'days';
      const otherDayLabel = `In ${daysUntilMatch} ${daysVariant}`;

      const timestamp = format(zonedTimeToUtc(event.value.startsAt, 'UTC'), 'hh:mm a');
      const todayLabel = `Today at ${timestamp}`;

      return daysUntilMatch ? otherDayLabel : todayLabel;
    });
    const isUSAView = computed(() => event.value.isUSAView);
    const showFootballPlayByPlay = computed(() => live.value && event.value?.sportId === '841e188b-0dcf-4f5f-974f-aa52c8cec95b');
    const showBasketballPlayByPlay = computed(() => live.value && event.value?.sportId === 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f');

    const expanded = ref(false);
    const expandedIcon = computed(() => (expanded.value ? 'chevron-up' : 'chevron-down'));
    const expandedLabel = computed(() => (expanded.value ? 'Hide details' : 'Show details'));
    const toggleExpansion = () => {
      expanded.value = !expanded.value;
    };

    const selectScoreboardTab = (tab) => {
      if (selectedScoreboardTab.value === tab) return;
      selectedScoreboardTab.value = tab;
    };

    const showPlayByPlay = computed(() => isPlayByPlayDisplayable(event.value));

    const { squad } = useSquad(event.value);
    const pitcherName = computed(() => {
      const player = find(
        squad.value || [],
        ({ playerIndex }) => event.value?.currentPitcher?.index === playerIndex,
      );
      return player?.shortName || '-';
    });
    const pitchCount = computed(() => {
      const value = event.value?.currentPitcher?.stats?.pitchCount;
      return isNil(value) ? '-' : value;
    });
    const timesThrough = computed(() => {
      const value = event.value?.currentPitcher?.stats?.timesThrough;
      return isNil(value) ? '-' : value;
    });
    const hitterName = computed(() => {
      const player = find(
        squad.value || [],
        ({ playerIndex }) => event.value?.currentHitter?.index === playerIndex,
      );
      return player?.shortName || '-';
    });

    const footballDownValue = computed(() => (isNil(event.value.downNumber) ? '-' : event.value.downNumber));
    const footballToGoValue = computed(() => (isNil(event.value.yardsToWinDown) ? '-' : event.value.yardsToWinDown));
    const footballOnValue = computed(() => (isNil(event.value.yardsToEndZone) ? '-' : event.value.yardsToEndZone));
    const footballPlayValueAvailable = computed(() => !isNil(event.value.isPlayRunning));
    const footballPlayValue = computed(() => !!event.value.isPlayRunning);

    return {
      event,
      teamInPossession,
      teamA,
      teamB,
      isSportSupported,
      isBaseball,
      isFootball,
      prematch,
      live,
      finished,
      canceled,
      time,
      isUSAView,
      showFootballPlayByPlay,
      showBasketballPlayByPlay,
      expanded,
      expandedIcon,
      expandedLabel,
      toggleExpansion,
      hasDpsTraderRole,
      selectedScoreboardTab,
      selectScoreboardTab,
      showPlayByPlay,
      pitcherName,
      pitchCount,
      timesThrough,
      hitterName,
      footballDownValue,
      footballToGoValue,
      footballOnValue,
      footballPlayValueAvailable,
      footballPlayValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.scoreboard {
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 8px;

  .button {
    stroke: #000;
  }

  .scoreboard__head {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .scoreboard__head-main {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 24px 16px 12px;
      min-height: 99px;
      background: #fff;
    }

    .scoreboard__info {
      flex: 1 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 4px;
    }

    .scoreboard__team {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto 1fr;
      column-gap: 5.5px;
      align-items: center;

      .shot-clock,
      .baseball-clock {
        grid-row: 1 / 3;
      }

      &.scoreboard__team--left {
        justify-content: flex-start;
        text-align: left;

        .scoreboard__team-name {
          grid-column: 2;
        }

        .football-timeout-indicators {
          grid-column: 2 / 3;
          justify-content: flex-start;
        }
      }

      &.scoreboard__team--right {
        justify-content: flex-end;
        text-align: right;

        .shot-clock,
        .baseball-clock {
          grid-column: 3;
        }

        .scoreboard__team-name {
          grid-row: 2;
        }

        .football-timeout-indicators {
          grid-column: 1 / 3;
          justify-content: flex-start;
        }
      }
    }

    .scoreboard__info-score {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 20px;
    }

    .scoreboard__info-time {
      font-size: 14px;
      border-radius: 12px;
      padding: 4px 8px;

      &.scoreboard__info-time--prematch {
        background: #e8efff;
        color: #175FFF;
      }

      &.scoreboard__info-time--live {
        background: #e5f7ee;
        color: #00BC57;
      }

      &.scoreboard__info-time--finished {
        color: #A9A9A9;
      }
    }

    .scoreboard__team-side {
      font-size: 12px;
      color: #A9A9A9;
      margin-bottom: 4px;
      text-transform: uppercase;
    }

    .scoreboard__team-name {
      display: block;
      font-size: 16px;
      width: 190px;
    }
  }

  .scoreboard__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 20px;

    .scoreboard-navigation {
      height: 33px;
      width: 100%;
      box-shadow: inset 0px -2px 0px #CDCDCD;
      display: flex;
      margin: 32px 0;

      .scoreboard-navigation-tab {
        height: 100%;
        padding: 0 8px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.selected {
          box-shadow: inset 0px -2px 0px #003C3C;
        }
      }
    }
  }

  .scoreboard__play-by-play {
    width: 100%;
    padding: 0 16px 12px;
  }

  .baseball-indicators {
    padding-bottom: 0;
  }

  .scoreboard__baseball-meta {
    display: grid;
    justify-content: space-around;
    grid-template-columns: 76px 76px 76px 76px;
    column-gap: 16px;
    padding: 8px 12px;
    width: 100%;
    max-width: 483px;
    margin: 0 auto;
    overflow-x: auto;

    &-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-label {
      display: flex;
      align-items: center;
      color: #6D6D6D;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 10px;
      line-height: 11.85px;
      text-transform: uppercase;
      gap: 4px;

      .icon {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        max-height: 12px;
      }
    }

    &-value {
      color: #191414;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .scoreboard__football-meta {
    display: grid;
    justify-content: space-around;
    grid-template-columns: 76px 76px 76px 76px;
    column-gap: 16px;
    padding: 8px 12px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    overflow-x: auto;
    margin-bottom: 20px;

    &-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-label {
      display: flex;
      align-items: center;
      color: #6D6D6D;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 10px;
      line-height: 11.85px;
      text-transform: uppercase;
      gap: 4px;

      .icon {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        max-height: 12px;
      }
    }

    &-value {
      color: #191414;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .event-details__stats-content-message {
    color: #6D6D6D;
    margin-top: -5px;
    margin-bottom: 16px;
  }

  .scoreboard__expansion {
    padding: 0 16px;
    position: relative;
  }

  .event-details__stats-tabs-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    height: 66px;
    width: 33px;
    cursor: pointer;

    &.event-details__stats-tabs-arrow--left {
      left: 16px;
    }

    &.event-details__stats-tabs-arrow--right {
      right: 16px;
    }
  }

  .scoreboard__expansion-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    stroke: #000;
    cursor: pointer;
    text-align: center;
    margin: 0 0 27.5px;
  }

  .event-details__stats-tabs {
    flex: 1;
    display: flex;
    gap: 8px;
    margin: 0 0 20px;
    overflow: auto;
  }

  .event-details__stats-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #FAFAFA;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 4px;
    width: 109px;
    min-width: 109px;
    height: 66px;
    color: #6D6D6D;
    font-size: 14px;
    gap: 7px;
    cursor: pointer;
  }

  .event-details__stats-tab-score {
    color: #000;
  }

  .event-details__stats-tab--active {
    background-color: #003C3C;
    color: #fff;

    .event-details__stats-tab-score {
      color: #fff;
    }
  }
}
</style>
